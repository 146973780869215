<template>
  <div id="collection">
    <Dialog ref="dialog" :config="config"></Dialog>
    <div class="ohead">
      <div class="myInput">
        <i class="el-icon-search"></i>

        <input type="text" :placeholder="this.$fanyi('请输入关键词搜索')" v-model="form.keywords"
          @keydown.enter="getGoodsLists" />
        <button @click="getGoodsLists">{{ $fanyi('搜索') }}</button>
      </div>
      <div class="btns">
        <button @click="delGoods('', '', 'all')">
          {{ $fanyi('选中移出') }}
        </button>
      </div>
    </div>

    <div :class="tagLists.length > 0 ? 'tallys' : 'newTallys'" v-if="tagLists.length > 0">
      <span :class="item.titleChecked ? 'active' : ''" @click="tabBtnClick(item)" v-for="(item, index) in tagLists"
        :key="index" :title="item.tag_name">{{ item.tag_name }}</span>
    </div>

    <div v-loading="loading">
      <div class="goods" v-if="goodsList.length != 0">
        <div class="newGoodsOpt" :class="{ activechecked: item.checked == true }" v-for="(item, index) in goodsList"
          :key="index">
          <input v-model="item.checked" @change="changechecked" type="checkbox" name="" id="" class="check-box" />
          <div class="oimg" @click="
            $fun.toCommodityDetails({
              goods_id: item.goods_id,
              shop_type: item.shop_type,
            })
            ">
            <el-image :src="item.image_url">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
          </div>
          <div class="otitle" :title="item.title">
            {{ item.title }}
          </div>
          <div class="odetail">
            <div class="oprice">
              <div>
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(item.price * $store.state.exchangeRate)
                  )
                }}
                <span> {{ $fun.RMBNumSegmentation(item.price) }}￥</span>
              </div>
              <div class="obtns">
                <img @click.stop="goodsTagOpen(item)" style="width: 23px; height: 23px" :src="item.labelUrl"
                  @mouseenter="changeImageSrc(1, 'hover', item)" @mouseleave="changeImageSrc(1, '', item)" alt="" />
                <img @click="delGoods(index, item)" style="width: 24px; height: 24px" :src="item.deleteUrl"
                  @mouseenter="changeImageSrc(2, 'hover', item)" @mouseleave="changeImageSrc(2, '', item)" alt="" />
              </div>
            </div>

            <div class="tagListEditContainer">
              <el-dialog :visible.sync="dialogVisible" width="514px" :show-close="false" :before-close="handleClose">
                <div class="tagListEditHeaderContainer flexAndCenter">
                  <div>{{ $fanyi('选择标签') }}</div>
                  <i class="el-icon-close" @click="dialogVisible = false"></i>
                </div>
                <div class="tagListEditCenterContainer">
                  <div class="tagListEditCenterChildrenHeader flexAndCenter">
                    <div class="addKeyWord flexAndCenter">
                      <el-input :placeholder="$fanyi('请输入关键词')" v-model="addTagTxt" @keydown.enter="addTag(item)">
                        <template slot="append">
                          <div @click="addTag(item)">
                            {{ $fanyi('新增') }}
                          </div>
                        </template>
                      </el-input>
                    </div>
                    <div class="removeTagBtn" @click="delTag()">
                      {{ $fanyi('移除') }}
                    </div>
                  </div>
                  <div class="tagListEditCenterChildrenFooter">
                    <div v-for="(item, index) in tagLists" :key="index" class="flexAndCenter">
                      <input type="checkbox" :checked="item.checked" @click="item.checked = !item.checked" name="" id=""
                        class="che" /><span :title="item.tag_name">{{ item.tag_name }}</span>
                    </div>
                  </div>
                </div>
                <span slot="footer" class="dialog-footer flexAndCenter">
                  <el-button @click="saveTag">{{ $fanyi('确认') }}</el-button>
                  <el-button type="primary" @click="dialogVisible = false">{{
                    $fanyi('取消')
                  }}</el-button>
                </span>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="tishi">
        {{ $fanyi(pageMsg) }}
      </div>
    </div>
    <div class="allpag">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
        :page-size="form.pageSize" layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Dialog from '../../../../components/public/newDialog.vue'

export default {
  data() {
    return {
      dialogVisible: false,
      pageMsg: '暂无数据',
      tagLists: [], //标签列表
      goodsList: [], //收藏商品列表
      addTagTxt: '', //添加标签的文本
      loading: false,
      goodsId: 0,
      total: 0,
      form: {},
      goodsListLength: 0,
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('删除'), this.$fanyi('取消')],
      }, // 弹框属性
    }
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {
    this.form.pageSize = 12
    this.getTagData()
    this.getGoodsLists()
  },
  mounted() {

  },
  methods: {
    // 删除商品
    delGoods(index, item, type) {
      var ids = []
      this.goodsList.forEach((item) => {
        if (item.checked == true) {
          ids.push(item.id)
        }
      })
      var idsstr = ids.join(',')

      if (ids.length == 0 && type == 'all') {
        return this.$message.warning(this.$fanyi('请至少选择一个商品'))
      }

      this.$refs.dialog.open(
        this.$fanyi('是否删除选中的商品'),
        () => {
          this.$api
            .favoriteGoodsDelete(
              type == 'all' ? { id: idsstr } : { id: item.id }
            )
            .then((res) => {
              if (res.code != 0) return
              if (
                Number(this.total) % Number(this.form.pageSize) == 1 &&
                this.form.page >
                parseInt(Number(this.total) / Number(this.form.pageSize))
              ) {
                this.form.page--
              }

              this.getGoodsLists()
              this.getTagData()
              setTimeout(() => {
                this.$store.dispatch('getLeftSidebarNum')
              }, 2000)


            })
        },
        () => { }
      )
    },
    // 添加商品标签
    async addTag(item) {
      this.$api
        .favoriteTagAdd({
          type: 1,
          tag_name: this.addTagTxt,
        })
        .then((res) => {

          if (res.code != 0) return
          this.addTagTxt = ''
          // 重新获取标签列表
          this.$api.favoriteTagList({ type: '1' }).then((res) => {
            this.tagLists = res.data
            // 调用处理标签列表事件
            this.tagDataProcessing()
            this.$forceUpdate()
          })
        })
    },
    //鼠标移入移出
    changeImageSrc(key, way, item) {
      switch (key) {
        case 1:
          way === 'hover'
            ? (item.labelUrl = require('../../../../assets/newImg/label2.png'))
            : (item.labelUrl = require('../../../../assets/newImg/label1.svg'))
          break
        case 2:
          way === 'hover'
            ? (item.deleteUrl = require('../../../../assets/newImg/delete2.png'))
            : (item.deleteUrl = require('../../../../assets/newImg/delete1.svg'))
          break
      }
    },
    // 删除商品标签
    delTag() {
      this.tagLists.forEach((item, index) => {
        if (item.checked === true) {
          this.$api
            .favoriteTagDelete({
              tag_id: item.tag_id,
            })
            .then((res) => {
              if (res.code != 0) return
              this.tagLists.splice(index, 1)
            })
        }
      })
    },
    handleClose(done) {
      this.$confirm(this.$fanyi('确认关闭？'))
        .then((_) => {
          done()
        })
        .catch((_) => { })
    },
    changechecked() {
      this.$forceUpdate()
    },
    //保存选中商品的tag信息
    saveTag() {
      let ids = []
      this.tagLists.forEach((item) => {
        if (item.checked) ids.push(item.tag_id)
      })
      ids = ids.join(',')
      let datas = {
        id: this.goodsId,
      }
      if (ids) {
        datas.tag_ids = ids
      }
      this.$api.favoriteGoodsToTags(datas).then((res) => {
        this.dialogVisible = false
        if (res.code != 0) return
        this.getGoodsLists()
      })
    },
    //打开单个商品标签弹窗
    goodsTagOpen(item) {
      this.goodsId = item.id
      let goodsTagIds = item.tag_ids.split(',')
      // 初始化商品标签列表数据
      this.tagLists.forEach((item) => {

        if (goodsTagIds.indexOf(String(item.tag_id)) != -1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      this.dialogVisible = true
      this.$forceUpdate()
    },
    // 标签按钮点击事件
    tabBtnClick(item) {
      // 设置选中便签的值
      item.titleChecked = !item.titleChecked
      this.form.page = 1
      this.getGoodsLists('tagGoods')
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getTagData() {
      this.$api.favoriteTagList({ type: '1' }).then((res) => {

        this.tagLists = res.data
        // 调用处理标签列表事件
        this.tagDataProcessing()
        // 调用获取商品收藏夹列表事件
      })
    },
    // 获取商品收藏夹列表
    async getGoodsLists(type) {
      this.loading = true
      let datas = {
        pageSize: this.form.pageSize,
        page: this.form.page,
        keywords: this.form.keywords,
      }
      let tagIds = []
      // 如果有筛选就循环遍历获取选中的标签id传给接口

      this.tagLists.forEach((item) => {
        if (item.titleChecked) tagIds.push(item.tag_id)
      })
      tagIds = tagIds.join(',')
      datas.tag_ids = tagIds

      await this.$api.favoriteGoodsList(datas).then((res) => {

        this.loading = false
        res.data.data.forEach((item) => {
          item.labelUrl = require('../../../../assets/newImg/label2.png')
          item.deleteUrl = require('../../../../assets/newImg/delete1.svg')
        })
        this.goodsList = res.data.data
        this.total = res.data.total
        this.goodsListLength = res.data.total
      })
      this.$nextTick(() => {
        if (document.querySelector('.shopdianpu')) {
          document.querySelector('.shopdianpu').innerText = this.total
        }

      })
      this.dataProcessing()
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagLists.forEach((item) => {
        item.checked = false
        item.titleChecked = false
      })
    },
    // 处理收藏夹商品数据
    dataProcessing() {
      this.goodsList.forEach((item) => {
        item.checked = false
      })
    },
    handleSizeChange(val) {
      this.form.page_num = val
      this.form.page = 1
      this.getGoodsLists()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getGoodsLists()
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
#collection {
  @import '../../../../css/mixin';
  width: 100%;
  width: 1180px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E2E2E2;

  .flexAndCenter {
    display: flex;
    align-items: center;
  }

  // 弹窗样式
  .tagListEditContainer {
    border-radius: 10px;

    /deep/.el-dialog {
      border-radius: 10px;
      // border: 1px solid #979797;
      width: 410px;
    }

    /deep/ .el-dialog__header {
      display: none;
    }

    /deep/ .el-dialog__body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    /deep/ .el-dialog__header,
    /deep/ .el-dialog__body,
    /deep/.el-dialog__footer {
      padding: 0;
      border-radius: 10px;
    }

    .tagListEditHeaderContainer {
      height: 64px;
      padding: 20px 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #f5f5f5;
      justify-content: space-between;

      div {
        color: #222222;
        font-size: 18px;
      }

      .el-icon-close {
        color: #000000;
        font-weight: bold;
        font-size: 26px;
      }
    }

    .tagListEditCenterContainer {
      margin: 10px 30px 0;

      .tagListEditCenterChildrenHeader {
        .addKeyWord {
          width: 330px;
          height: 52px;

          justify-content: center;

          .el-input {
            width: 320px;
            height: 40px;

            border: 2px solid #ff730b;
            border-radius: 4px;

            display: flex;
            align-items: center;

            /deep/ .el-input__inner {
              height: 36px;
              // border: 1px solid #cdcdcd;
              // background: #fff2e8;
              border: 0 !important;
              border-right: none;
              // border-radius: 0;
              width: 302px;
            }

            /deep/ .el-input-group__append {
              width: 62px;

              background: #ff730b;

              font-size: 14px;
              height: 39px;
              width: 64px;
              color: #fff;
              margin-top: -1px;
              border-radius: 0;
              font-size: 14px;
              line-height: 40px;
              border: 1px solid #ff730b;
              border-right: none;
              padding: 0;
              text-align: center;
              cursor: pointer;
              border-radius: 0px 4px 2px 0px;
            }
          }
        }

        .removeTagBtn {
          width: 64px !important;
          height: 40px;
          background: #222222;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          margin-left: 20px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
        }
      }

      .tagListEditCenterChildrenFooter {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        //border-bottom: 1px solid #F5F5F5;
        div {
          margin-bottom: 20px;
          margin-right: 30px;

          input[type='checkbox'] {
            width: 17px;
            height: 17px;
            border-radius: 3px;
            // border: 1px solid #cdcdcd;
          }

          span {
            display: inline-block;
            color: #222222;
            font-size: 14px;
            width: 60px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 6px;
          }
        }
      }
    }

    .dialog-footer {
      height: 100px;
      padding: 30px 0;
      justify-content: center;

      // border: 1px solid #f5f5f5;
      button {
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0;

        //background: #FF730B;
      }

      button:first-child {
        width: 120px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;
        background: #ff730b;
        margin-right: 40px;
        color: #ffffff;
        border: 1px solid #ff730b;
      }

      .el-button+.el-button {
        margin-left: 0;
      }

      button:last-child {
        background: #fff;
        width: 120px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        color: #222222;
        border: 1px solid #e2e2e2;
      }
    }
  }

  .ohead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 30px;
    padding-bottom: 0px;

    .myInput {
      height: 40px;
      background: #ffffff;
      border-radius: 4px;

      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;

      >i {

        font-size: 16px;
        color: #CDCDCD;
        margin: 10px 11px 9px 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
      }

      >input {
        width: 320px;
        height: 40px;
        border: 1px solid #E2E2E2;
        height: 100%;
        border-radius: 4px;
        font-size: 14px;
        padding-left: 40px;
        padding-right: 10px;
        transition: 0.3s;

        &:focus {
          border-color: #1e2997;
        }
      }

      >button {
        width: 80px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;
        color: #ffffff;
        font-size: 16px;
        line-height: 18px;
        margin-left: 20px;


      }
    }

    .btns {
      >button {
        min-width: 140px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff730b;
        padding: 0 10px;
        margin-right: -5px;
        font-size: 16px;

        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff730b;
      }
    }
  }

  .newTallys {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  .tallys,
  .newTallys {
    margin-left: 30px;
    width: 95%;
    min-height: 30px;
    // padding-top:30px ;
    // padding-bottom: 40px;
    border-radius: 6px;

    display: flex;
    flex-wrap: wrap;

    span {
      display: block;
      max-width: 120px;
      min-height: 24px;
      background: #f1f1f1;
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      margin: 0 10px 10px 0;
      padding: 6px;

      box-sizing: border-box;
      font-size: 12px;
      cursor: pointer;

      font-weight: 400;
      color: #222222;

      &.active {
        background: #ff730b;
        border-radius: 4px;
        border: 1px solid #ff730b;
        color: #fff;
      }
    }
  }

  .goods {
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;

    .newGoodsOpt {
      width: 264.9px;
      min-height: 351px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      position: relative;
      overflow: hidden;
      margin: 0 19.3px 20px 0; // overflow: hidden;
      transition: all 0.1s linear;

      &:nth-child(4n) {
        margin-right: 0 !important;
      }



      .check-box {
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border: solid 1px #dddddd;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 1;
        cursor: pointer;
        -webkit-appearance: none;
        // -webkit-user-select: none;
        user-select: none;
        -webkit-transition: background-color ease 0.1s;
        transition: background-color ease 0.1s;

        &:checked {
          background: #ff730b;
        }

        &:checked::after {
          content: '';
          top: 2.5px;
          left: 2.5px;
          position: absolute;
          background: transparent;
          border: #fff solid 2px;
          border-top: none;
          border-right: none;
          height: 6px;
          width: 10px;
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }

      .oimg {
        overflow: hidden;
        cursor: pointer;
        width: 265px;
        height: 265px;

        /deep/ .el-image {
          $imgSize: 265px;
          width: 265px;
          height: 265px;
          transition: 0.3s;
          text-align: center;
          margin-bottom: 3px;
          border-radius: 0;
          transition: 0.3s;

          &:hover {
            transform: scale(1.2);
          }

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }
      }

      .otitle {
        width: 245px;
        font-size: 14px;
        min-height: 38px;
        color: #222;
        line-height: 20px;


        margin: 0 auto 3px;
        margin-top: 5px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 8px;
      }

      .odetail {
        padding: 0 10px;

        .oprice {
          font-size: 20px;
          font-size: 18px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #ff730b;
          height: 30px !important;
          justify-content: space-between;
          display: flex;

          span {
            font-size: 14px;
          }

          .obtns {
            img {
              width: 24px;
              height: 24px;

              &:hover {
                cursor: pointer;
              }

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .activechecked {
      border: 2px solid #FF730B !important;
    }
  }

  .tishi {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 25px;
    font-weight: bold;
    color: #bcb8b8;
  }

  .allpag {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    margin-top: 0;

    .el-pagination {
      display: flex;
      align-items: center;
    }

    >a {
      display: inline-block;
      border: 1px solid #dcdfe6;
      height: 28px;
      // background-color: white;
      text-align: center;
      padding: 0 10px;
      font-size: 13px;
      line-height: 28px;
      border-radius: 0;
    }

    /deep/.btn-prev {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-right: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /deep/.btn-next {
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 20px;
      padding: 0;
      margin-left: 30px;

      .el-icon {
        font-size: 15px;
      }
    }

    /* 分页被选中的页码样式 */
    /deep/ .el-pager li {
      margin: 0 10px;
      // border-radius: 0;
      min-width: 20px !important;
      font-size: 14px;
      height: 30px;
      color: #999999;
      // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

      &:hover {
        color: #ff730b;
      }

      &.active {
        color: #ff730b;
        border-bottom: 2px solid #ff730b;
        width: 20px !important;
      }
    }
  }
}

.pop-up {
  width: 246px;
  background: #ffffff;

  padding: 20px;
  z-index: 2;
  align-items: center;

  p {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 20px;
    }

    .che {
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      border: solid 1px #dddddd;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      font-size: 0.8rem;
      margin: 0;
      margin-right: 6px;
      padding: 0;
      position: relative;
      display: inline-block;
      vertical-align: top;
      cursor: default;
      -webkit-appearance: none;
      // -webkit-user-select: none;
      user-select: none;
      -webkit-transition: background-color ease 0.1s;
      transition: background-color ease 0.1s;

      &:checked {
        background: #1673ff;
      }

      &:checked::after {
        content: '';
        top: 1px;
        left: 1px;
        position: absolute;
        background: transparent;
        border: #fff solid 2px;
        border-top: none;
        border-right: none;
        height: 6px;
        width: 10px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    >span {
      display: block;
      height: 16px;
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;

      color: #000000;
      line-height: 16px;
      margin-right: auto;
    }
  }

  .addTagBtn {
    position: relative;
    height: 32px;

    input {
      width: 136px;
      height: 100%;
      background: #ffffff;
      border: 1px solid rgba(96, 139, 255, 0.3);
      padding: 0 12px;
      font-size: 14px;
    }

    /deep/ .el-button {
      position: absolute;
      right: 7px;
      top: 0;
      height: 32px;
      width: 64px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
    }
  }
}
</style>
